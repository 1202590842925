import React from 'react';
import styled from 'styled-components';
import SEO from '@components/seo';
import Layout from '@layouts/default-layout';
import Section from '@components/section';
import Wrapper from '@components/wrapper.styled';
import Text from '@components/text';

const Centered = styled.div`
  text-align: center;
`;

const NotFoundPage = (): JSX.Element => (
  <Layout mode="light">
    <SEO title="404: Not found" />
    <Section backgroundColor="white">
      <Wrapper>
        <Centered>
          <Text tag="h2" size="heading1" type="title" stringText=":(" />
          <Text
            tag="h1"
            size="heading4"
            type="title"
            stringText="Page not found."
            fontColor="dark"
          />
          <p>We can&apos;t seem to find the page you&apos;re looking for.</p>
        </Centered>
      </Wrapper>
    </Section>
  </Layout>
);

export default NotFoundPage;
